import React, { useState } from 'react';
import * as styles from './default-product.module.scss';

import Layout from "../page-components/layout/layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import GsProductHeaderImage from "../page-components/product/gsProductHeaderImage";
import GsFullModal from "../page-components/modal/gsFullModal";
import GsContactModal from "../page-components/modal/gsContactModal";
import DefaultProductConfiguration from "./default-product-configuratior";
import GsProductDeliveryTime from "../page-components/product/gsProductDeliveryTime";

import ChevronRightSVG from "../icons/fontawesome/light/chevron-right.svg";
import ChevronLeftSVG from "../icons/fontawesome/light/chevron-left.svg";

const DefaultProductTemplate = (
        {
            data
        }
    ) => {

    const [ currentImageModalIndex, setCurrentImageModalIndex ] = useState(null);
    const [ currentTab, setCurrentTab ] = useState('description');

    const [ showContactModal, setShowContactModal ] = useState(false);

    const product = data.shopifyProduct;

    let technicalData = data.shopifyProduct.metafields.find( m => m.key === "technicalData");
    let free = data.shopifyProduct.metafields.find( m => m.key === "free");
    let freeLines = free ? free.value.split('\n') : [];

    console.log('*** product');
    console.log(product);

    // If featuredImage exists and it's not already the first image, prepend it to images
    if (product.featuredImage && (!product.images[0] || product.images[0].id !== product.featuredImage.id)) {
        product.images = [product.featuredImage, ...product.images];
    }

    let otherImages = product.images.filter( (p, i) => i !== 0 );
    const [ availableVariants, setAvailableVariants ] = useState(null);

    const [ selectedOptions, setSelectedOptions ] = useState(null);

    return <>

        <GsContactModal isOpen={showContactModal}
                        selectedOptions={selectedOptions}
                        product={product}
                        requestClose={ () => setShowContactModal(false) }
        >
            <div className={"mt-5 mb-4"}>
            Ein direkter Online-Kauf dieses Artikels ist aufgrund Hersteller-Einschränkungen nicht möglich. <br />
            Bitte hinterlassen Sie Ihre Kontaktdaten - Wir werden Sie asbalds kontaktieren.
            </div>
        </GsContactModal>

        <Layout>
            <div className={styles.defaultProductPage}>
                <div className="container bg-white h-100">

                        <div className={"row lg:pt-5 pb-4 " + styles.top}>
                            <div className="col-12 col-lg-6 text-center">
                                <GsProductHeaderImage
                                    className={styles.headerImage + " c-pointer img-fluid mb-4 lg:mb-0"}
                                    onClick={
                                        () => {
                                            if (!product.images || product.images.length === 0) {
                                                return null;
                                            }

                                            const image = product.images[0];

                                            if (!image.localFile?.childImageSharp?.gatsbyImageData) {
                                                return null;
                                            }
                                            setCurrentImageModalIndex(0);
                                        }
                                    }
                                    product={product}
                                    availableVariants={availableVariants}
                                />

                            </div>

                            <div className="col-12 col-lg-6 pe-lg-5">
                                <h1>{product.title}</h1>
                                <h3>{product.productType}</h3>

                                {freeLines.length > 0 &&
                                <div className={"row py-3"}>
                                    { freeLines.map(
                                        (f) => {
                                            return <div className={"d-flex align-items-center mb-2 " + styles.freeBadgeWrapper}>
                                                <div className={styles.freeBadge}>Kostenlos</div>
                                                <p> { f }</p>
                                            </div>
                                        }
                                    )}
                                </div>
                                }

                                <DefaultProductConfiguration
                                    product={product}
                                    handleAvailableVariants={ (variants) => setAvailableVariants(variants) }
                                    requestContactModal={ (selectedOptions) => {
                                        setSelectedOptions(selectedOptions);
                                        setShowContactModal(true);
                                    } } />

                                <GsProductDeliveryTime className={"mt-3"} product={product} />


                            </div>



                        </div>


                        <div className="row">
                            <div className="d-flex " style={ { overflowX: "auto"} } >
                                {otherImages?.length > 0 && <>
                                    {otherImages
                                        .filter( i => !!i.originalSrc )
                                        .map(
                                        i =>  <img src={i.originalSrc}
                                                          className={styles.productImg + " c-pointer"}
                                                          onClick={
                                                            () => {
                                                                // find out which image
                                                                const images = product?.images;
                                                                let idx = 0;

                                                                for (const image of images) {
                                                                    if (i.id === image.id) {
                                                                        setCurrentImageModalIndex(idx);
                                                                        return;
                                                                    }

                                                                    idx++;
                                                                }

                                                            }
                                                          } />
                                    )}
                                </>
                                }
                            </div>
                        </div>


                        <div className="row mt-5">

                            <div className={styles.tabs + " d-flex mb-4"}>
                                <div className={styles.tab + " " + (currentTab === 'description' ? styles.tabActive : styles.tabInactive) }
                                    onClick={
                                        () => {
                                            setCurrentTab('description');
                                        }
                                    }
                                >
                                    Beschreibung
                                </div>

                                <div className={styles.tab + " " + ( (!technicalData) ? styles.tabDisabled : (currentTab === 'technicalData' ? styles.tabActive : styles.tabInactive) )}
                                    onClick={
                                        () => {
                                            if (!technicalData) return;
                                            setCurrentTab('technicalData');
                                        }
                                    }
                                >
                                    Technische Daten
                                </div>
                            </div>

                        </div>

                        {product.descriptionHtml && currentTab === 'description' &&
                        <div className={"row tw-px-1 lg:tw-px-5 pb-5"}>
                            <h4 className={"text-primary-dark mb-2"}>Beschreibung</h4>
                            <div className={styles.htmlContent}
                                 dangerouslySetInnerHTML={
                                    {
                                        __html: product.descriptionHtml
                                    }
                                }
                            />
                        </div>
                        }

                        {technicalData && currentTab === 'technicalData' &&
                        <div className={"row tw-px-1 lg:tw-px-5 pb-5"}>
                            <h4 className={"text-primary-dark mb-2"}>Technische Daten</h4>
                            <div className={styles.htmlContent}
                                 dangerouslySetInnerHTML={
                                    {
                                        __html: technicalData.value
                                    }
                                }
                            />
                        </div>
                        }

                </div>
            </div>
        </Layout>

        { currentImageModalIndex !== null &&
        <GsFullModal title={"Bildansicht"}
                     requestClose={() => setCurrentImageModalIndex(null)}
                     isOpen={true}>

            <div className={"d-flex w-full justify-content-between mt-2 px-4 " + styles.imageModalActions}>

                <a className={"c-pointer d-flex align-items-center"}
                    onClick={
                        () => {
                            let newIndex = currentImageModalIndex - 1;
                            if (newIndex < 0) {
                                newIndex = product?.images.length - 1;
                            }

                            setCurrentImageModalIndex(newIndex);
                        }
                    }
                >
                    <ChevronLeftSVG className={"svgFill me-2"} />
                    Vorheriges Bild
                </a>

                <a className={"c-pointer d-flex align-items-center"}
                   onClick={
                       () => {
                           let newIndex = currentImageModalIndex + 1;
                           if (newIndex >= product?.images?.length) {
                               newIndex = 0;
                           }

                           setCurrentImageModalIndex(newIndex);
                       }
                   }
                >
                    Nächstes Bild
                    <ChevronRightSVG className={"svgFill ms-2"} />
                </a>
            </div>

            <img className={styles.modalImage} alt={"Detail"} src={product?.images[currentImageModalIndex]?.originalSrc} />

        </GsFullModal>
        }


        {/*<JSONPretty data={product} />*/}

    </>

};

export default DefaultProductTemplate;

export const query = graphql`
    query productDetailTemplate($id: String!) {
        shopifyProduct(id: { eq: $id }) {
              title
              vendor
              id
              shopifyId
              handle
              tags
              productType
              metafields {
                namespace
                key
                value
              }
              images {
                id
                originalSrc
              }
              descriptionHtml
              description
              createdAt
              totalInventory
              productType
              variants {
                id
                shopifyId
                title
                selectedOptions {
                  value
                  name
                }
                image {
                    id
                    originalSrc
                }
                price
                compareAtPrice
              }
              priceRangeV2 {
                maxVariantPrice {
                  amount
                }
                minVariantPrice {
                  amount
                }
              }
              featuredImage {
                id
                originalSrc
              }
              options {
                name
                values
              }
        }
    }
`
